import { ReservationOrigin } from '@/app/_graphql/types-core';
import { SpecialityByIdQueryResult, WorkgroupByIdQueryResult } from '@/graphql/types';
import { StateCreator } from 'zustand';

interface BookingInformationsmHandlers {
  setBookingInformation: (bookingInformation: BookingInformationFields) => void;
  setReservationOrigin: (reservationOrigin: ReservationOrigin | undefined) => void;
  setAllowedPayments: (allowedPayments: ClinicAllowedPayments) => void;
  resetBookingInformation: () => void;
  setBookingInformationLoading: (isLoading: boolean) => void;
}

export interface ClinicAllowedPayments {
  online: boolean;
  onsite: boolean;
  deferred: boolean;
}

export type BookingInformationFields = {
  ambulatoryId: string;
  ambulatory: WorkgroupByIdQueryResult['workgroupById'] | undefined;
  availabilitySlotId: string;
  startTime: number | undefined;
  specialityId: string;
  speciality: SpecialityByIdQueryResult['specialityById'] | undefined;
  doctorId: string;
  clinicAllowedPayments: ClinicAllowedPayments;
  reservationOrigin?: ReservationOrigin | undefined;
};

interface BookingInformationsContent {
  bookingInformations: BookingInformationFields;
}

interface BookingInformationsOthers {
  isBookingInformationsLoading: boolean;
}

export interface BookingInformationsState extends BookingInformationsmHandlers, BookingInformationsContent, BookingInformationsOthers {}

const initialBookingInformation: BookingInformationFields = {
  ambulatoryId: '',
  ambulatory: undefined,
  availabilitySlotId: '',
  specialityId: '',
  speciality: undefined,
  doctorId: '',
  clinicAllowedPayments: {
    online: false,
    onsite: false,
    deferred: false,
  },
  startTime: undefined,
  reservationOrigin: undefined,
};

export const createBookingInformationsSlice: StateCreator<BookingInformationsState, [], [], BookingInformationsState> = set => ({
  bookingInformations: initialBookingInformation,
  isBookingInformationsLoading: true,

  setBookingInformation: booking => {
    set({ bookingInformations: { ...booking } });
  },

  setAllowedPayments: allowedPayments => {
    set(state => ({ bookingInformations: { ...state.bookingInformations, clinicAllowedPayments: allowedPayments } }));
  },

  setReservationOrigin: reservationOrigin => {
    set(state => ({ bookingInformations: { ...state.bookingInformations, reservationOrigin } }));
  },

  resetBookingInformation: () => {
    set({
      bookingInformations: { ...initialBookingInformation },
      isBookingInformationsLoading: true,
    });
  },
  setBookingInformationLoading: isBookingInformationsLoading =>
    set({
      isBookingInformationsLoading,
    }),
});
