'use client';

import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';
import Footer from '@/components/layouts/defaultLayout/footer';
import { FooterStoryblok } from '@/types/types-storyblok';
import dynamic from 'next/dynamic';

const FooterTantosvago = dynamic(() => import('@/components/layouts/defaultLayout/footerTantosvago'));

const FooterRSC: React.FC<{ component: FooterStoryblok | undefined; componentTantosvago: FooterStoryblok | undefined }> = ({
  component,
  componentTantosvago,
}) => {
  const isTantosvago = useIsTantosvago();
  if (componentTantosvago?.component === 'Footer' && isTantosvago) return <FooterTantosvago component={componentTantosvago} />;
  if (component?.component === 'Footer' && !isTantosvago) return <Footer component={component} />;
  return null;
};
export default FooterRSC;
